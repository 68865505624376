import { FieldValue, Primitive } from 'firebase/firestore';

export function assertNonNull<T>(v: T): asserts v is NonNullable<T> {
  if (v === null || v === undefined) {
    throw Error('value is null');
  }
}

export type PartialWithFieldValue<T> =
  | Partial<T>
  | (T extends Primitive
      ? T
      : T extends Record<never, never>
      ? {
          [K in keyof T]?: T[K] | FieldValue;
        }
      : never);
