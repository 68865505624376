import { useStore } from 'hooks/useStore';
import { useMessageStatus } from './useMessageStatus';
import { observer } from 'mobx-react';
import { Tabs } from '../../../components/basics/Tabs';
import { ComponentProps } from 'react';

type Props = Omit<
  ComponentProps<typeof Tabs>,
  'entries' | 'value' | 'onChange'
>;

export const MessageTabs = observer((props: Props) => {
  const store = useStore();
  const { statuses, status, onChange } = useMessageStatus(store);
  return (
    <Tabs {...props} entries={statuses} value={status} onChange={onChange} />
  );
});
