import { ReactElement, useMemo } from 'react';
import { SettingPageContainer } from '../SettingPageContainer/SettingPageContainer';
import { Tabs } from '../../../../components/basics/Tabs';
import { matchPath, useHistory, useLocation } from 'react-router-dom';

type Props = {
  title: string;
  pages: {
    pathname: string;
    label: string;
    exact?: boolean;
    element: () => ReactElement;
  }[];
};

export const TabsSettingPageContainer = ({ title, pages }: Props) => {
  const { pathname } = useLocation();
  const history = useHistory();
  const currentPage = useMemo(
    () =>
      pages.find((page) =>
        matchPath(pathname, { path: page.pathname, exact: page.exact })
      ),
    [pages, pathname]
  );
  const handleChange = (pathname: string) => {
    history.push(pathname);
  };
  return (
    <SettingPageContainer title={title}>
      <Tabs
        entries={pages.map((page) => ({
          label: page.label,
          value: page.pathname,
        }))}
        value={currentPage?.pathname ?? ''}
        onChange={handleChange}
      />
      <div className="mt-4">{currentPage && currentPage.element()}</div>
    </SettingPageContainer>
  );
};
