import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { Button, Mentions } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { observer } from 'mobx-react';

import store from 'store';
import Avatar from 'App/Common/Avatar';

const { Option } = Mentions;

const countString = function (str, seq) {
  return str.split(seq).length - 1;
};

export const CommentEditor = observer(
  ({ comment, placeholder, useMention, onChange, onSave, onCancel }) => {
    const [saving, setSaving] = useState(false);
    const [rows, setRows] = useState('1');
    const rootRef = useRef(null);

    useEffect(() => {
      const input = rootRef.current?.querySelector('textarea');
      if (!input) {
        return;
      }
      input.focus();
      input.setSelectionRange(input.value.length, input.value.length);
    }, []);

    const handleChange = (value) => {
      const rows = countString(value, '\n') + 1;
      setRows(rows > 5 ? 5 : rows);
      onChange(value);
    };

    const onKeyDown = async (e) => {
      if ((e.metaKey || e.ctrlKey) && e.key === 'Enter') {
        setSaving(true);
        try {
          await onSave();
          await new Promise((r) => setTimeout(r, 2000));
        } finally {
          setSaving(false);
        }
      } else if (e.key === 'Escape') {
        onCancel();
      }
    };

    return (
      <Root ref={rootRef}>
        <InputWrapper onKeyDown={onKeyDown}>
          {useMention ? (
            <Mentions
              rows={rows}
              placeholder={placeholder}
              value={comment.text}
              onChange={handleChange}
              disabled={saving}
              placement="top"
            >
              <Option value="all">
                <Avatar name="全員" size="small" />
                <Name>all（チーム全員にメンションします）</Name>
              </Option>
              {store.users.map((u) =>
                store
                  .getUsersByTeamId(comment.teamId)
                  .some((m) => u.id === m.id) ? (
                  <Option value={u.name} key={u.id}>
                    <Avatar user={u} size="small" />
                    <Name>{u.name}</Name>
                  </Option>
                ) : (
                  <Option value={u.name} key={u.id} disabled>
                    <Avatar user={u} size="small" />
                    <Name>(チーム外){u.name}</Name>
                  </Option>
                )
              )}
            </Mentions>
          ) : (
            <TextArea
              placeholder={placeholder}
              value={comment.text}
              onChange={(e) => handleChange(e.target.value)}
              disabled={saving}
              style={{ resize: 'none' }} // テキストエリア右下のサイズ調整部分を消す
              autoSize
            />
          )}
        </InputWrapper>
        <footer>
          <Button type="link" disabled={saving} onClick={onCancel}>
            キャンセル
          </Button>
          <Button
            type="primary"
            style={{ borderRadius: '2rem' }}
            disabled={saving}
            onClick={onSave}
          >
            保存
          </Button>
        </footer>
      </Root>
    );
  }
);

const Root = styled.div`
  display: flex;
`;

const InputWrapper = styled.div`
  flex: 1;
  textarea {
    padding-top: 7px;
  }
`;

const Name = styled.span`
  margin-left: 4px;
`;
