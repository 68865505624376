import * as React from 'react';
import type { SVGProps } from 'react';
const SvgEdit = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    fill="none"
    viewBox="0 0 16 16"
    {...props}
  >
    <path
      fill="currentColor"
      d="M1.75 14.25h.917l9.229-9.228-.917-.917-9.229 9.23v.916ZM14.542 4.127l-2.667-2.667.871-.871c.239-.239.535-.354.89-.347.354.006.649.128.885.364l.896.896c.236.236.354.528.354.875s-.118.639-.354.874l-.875.876ZM1.132 15.5a.612.612 0 0 1-.45-.182.61.61 0 0 1-.182-.45v-1.783c0-.085.014-.161.042-.23a.635.635 0 0 1 .145-.21L11 2.335l2.667 2.667L3.354 15.313a.62.62 0 0 1-.213.146.622.622 0 0 1-.224.042H1.132ZM11.438 4.563l-.459-.458.917.917-.459-.459Z"
    />
  </svg>
);
export default SvgEdit;
