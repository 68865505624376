import { observer } from 'mobx-react';
import { FC, useEffect, useState } from 'react';
import store from 'store';

type Email = { name?: string; address: string };

export const useContactNameByEmail = ({
  email,
  teamId,
}: {
  email: Email;
  teamId: string;
}): { name: string; loading: boolean } => {
  const [name, setName] = useState(email.name || email.address);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setLoading(true);
    store.contactStore
      .getContactNameByEmail({
        teamId,
        emailAddress: email.address,
        emailName: email.name,
      })
      .then((newName) => {
        if (newName && newName !== name) {
          setName(newName);
        }
      })
      .catch(console.error)
      .finally(() => setLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [email, teamId, store.contactStore.timestamp]);

  return { name, loading };
};

const Contact = observer(
  ({ email, teamId, sep }: { email: Email; teamId: string; sep: string }) => {
    const { name } = useContactNameByEmail({ email, teamId });
    return (
      <span>
        {name}
        {sep}
      </span>
    );
  }
);

export const Contacts: FC<{ emails: Email[]; teamId: string }> = ({
  emails,
  teamId,
}) => {
  return (
    <>
      {emails.map((email, index) => (
        <Contact
          key={index}
          email={email}
          teamId={teamId}
          sep={index < emails.length - 1 ? ', ' : ''}
        />
      ))}
    </>
  );
};
