import { companyAtom, companyLoadingAtom } from 'atoms/auth';
import { atom, useAtom, useAtomValue } from 'jotai';
import { useEffect } from 'react';
import { db } from '../../firebase';
import { doc, onSnapshot } from 'firebase/firestore';

type StripeProduct = {
  id: string;
  name: string;
  maxInboxes: number;
  maxUsers: number;
  plan: 'light' | 'standard' | 'business' | 'enterprise';
  version: number;
  maxTemplates?: number | null;
  maxUserGroups?: number | null;
  supportReportMessageByTags?: boolean;
  customStatusSupported?: boolean;
};

export const signInCompanyStripeProductAtom = atom<StripeProduct | null>(null);
export const signInCompanyStripeProductLoadingAtom = atom<boolean>(true);

/**
 * ログインしているcompanyが利用している料金プラン（stripeProduct）を返す
 *
 * @returns [stripeProductData, loading]
 */
export const useSignInCompanyStripeProduct = (): [
  StripeProduct | null,
  boolean,
] => {
  const company = useAtomValue(companyAtom);
  const companyLoading = useAtomValue(companyLoadingAtom);
  const [stripeProduct, setStripeProduct] = useAtom(
    signInCompanyStripeProductAtom
  );
  const [loading, setLoading] = useAtom(signInCompanyStripeProductLoadingAtom);

  useEffect(() => {
    // すでに stripeProduct が取得済みの場合は、処理スキップ
    if (companyLoading || !company || stripeProduct) {
      return;
    }

    setLoading(true);

    const query = doc(
      db,
      `stripe/collections/products/${company.stripeProductId}`
    );

    const unsubscribe = onSnapshot(query, (snapshot) => {
      if (!snapshot.exists()) {
        setLoading(false);
        return;
      }

      const data = snapshot.data();

      setStripeProduct({
        id: snapshot.id,
        name: data.name,
        maxInboxes: data.metadata.maxInboxes || null,
        maxUsers: data.metadata.maxUsers || null,
        plan: data.metadata.plan,
        version: data.metadata.version,
        maxTemplates:
          typeof data.metadata.maxTemplates !== 'undefined'
            ? Number(data.metadata.maxTemplates)
            : null,
        maxUserGroups:
          typeof data.metadata.maxUserGroups !== 'undefined'
            ? Number(data.metadata.maxUserGroups)
            : null,
        supportReportMessageByTags:
          data.metadata.supportReportMessageByTags === 'true',
        customStatusSupported: data.metadata.customStatusSupported === 'true',
      });

      setLoading(false);
    });

    return () => unsubscribe();
  }, [company, companyLoading, stripeProduct]);

  return [stripeProduct, loading];
};
